import React, {useContext, useEffect} from 'react';
import Layout from '@/shared/layout/Layout';
import css from './home.module.scss';
import {withPageWrapper} from '@/services/utils/withPageWrapper';
import PageWrapper from '@/shared/layout/page/PageWrapper';
import HomeHeader from './components/Header/HomeHeader';
import HomeBlog from './components/Blog/HomeBlog';
import HomeHowTo from './components/HowTo/HomeHowTo';
import HomeThemes from './components/Themes';
import UserContext from '../../context/user/UserContext';
import IAdvizeService from '@/services/utils/IAdvizeService';
import PagesDataService from '@/services/domain/PagesDataService';
import PublicationsService from '@/services/domain/PublicationsService';
import OpenGraphHead from "@/shared/OpenGraphHead/OpenGraphHead";
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { COMMON_NAMESPACES } from '../../i18n';

const namespacesRequired = [...COMMON_NAMESPACES, 'home', 'pot', 'concept', 'publications'];

const Home = ({t, principal, pagesData, topPublications}) => {

  const userContext = useContext(UserContext);
  const {activeList, isFetching} = userContext;

  useEffect(() => {
    IAdvizeService.injectPersonalDataScript({principal}, IAdvizeService.IADVIZE_PAGE_TYPES.HOMEPAGE);
  }, []);


  return (
    <Layout principal={principal}
            pageClass={css.home}
            homePage={true}
    >

      <PageWrapper customClass={css.noPadding}>

        <OpenGraphHead title={t('home:meta-title')}
                       description={t('home:meta-description')}/>

        <HomeHeader activeList={activeList}
                    isFetching={isFetching}
                    pagesData={pagesData}
        />
        <HomeBlog publications={topPublications}/>
        <HomeThemes/>
        <HomeHowTo activeList={activeList}/>

      </PageWrapper>

    </Layout>);
};

Home.getInitialProps = async (ctx) => {
    const [pagesData, topPublications] = await Promise.all([
        PagesDataService.get(ctx),
        PublicationsService.getTopThree(ctx)
          .catch(error => {
            console.log("Error while retrieving top three publications for home page : ", JSON.stringify(error));
            return null;
          })
    ]);

  return {pagesData, topPublications};
};

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, namespacesRequired)),
    },
  }
}


export default withPageWrapper({namespacesRequired: namespacesRequired})(Home);
