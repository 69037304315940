import React from 'react';
import {withTranslation} from '../../../../i18n';
import ListTitle from '../../../../shared/textes/ListTitle/ListTitle';
import Link from "next/link";
import css from './HomeBlog.module.scss';
import LinksProvider from "@/services/http/LinksProvider";
import PublicationsService from "@/services/domain/PublicationsService";
import StringService from "@/services/utils/StringService";
import Image from 'next/image';

const namespacesRequired = ['home'];

const HomeBlog = ({publications, t}) => {

    return (
        <div className={css.blog}>
            {publications && publications.length &&
            <React.Fragment>
                <ListTitle title={t('home:blog.title')}
                           subtitle={t('home:blog.subtitle')}
                />
                <div className={css.articles}>
                    {publications[0] && <Link href={PublicationsService.getPublicationLink(publications[0], t)}>
                        <a className={css.card}>
                            <div className={css.article}>
                                <Image  layout="fill" objectFit="cover" objectPosition="center" src={publications[0].cover_photo.url} width="100%" height="100%" alt='pub0_url'/>
                            </div>
                            <span>
                                <h2>{StringService.capitalizeFirst(publications[0].title)}</h2>
                                <p>{t('publications:publication.category-' + publications[0].category)}</p>
                            </span>
                        </a>

                    </Link>}
                    <div className={css.flex_column}>
                        {publications.slice(1, 3).map((publication, index) => <Link href={PublicationsService.getPublicationLink(publication, t)}>
                            <a className={`${css.card} ${css.card__half}`}>
                                <div className={css.article}>
                                    <Image
                                        layout="fill" objectFit="cover" objectPosition="center" 
                                        src={publication.cover_photo.url}
                                        alt={`pub${index}_url`}
                                        width="100%" height="100%"
                                    />
                                </div>
                                <span>
                                    <h2>{StringService.capitalizeFirst(publication.title)}</h2>
                                    <p>{t('publications:publication.category-' + publication.category)}</p>
                                </span>
                            </a>
                        </Link>)}
                    </div>
                </div>
                <div className={css.btn_container}>
                    <Link href={LinksProvider.get(LinksProvider.ROUTES.PUBLICATION.ALL_NO_PARAMS)}>
                        <a className={css.blog__btn_light}>
                            <span>{t('home:blog.more-articles')}</span>
                        </a>
                    </Link>
                </div>
            </React.Fragment>}
        </div>
    );
};

export default withTranslation(namespacesRequired)(HomeBlog)
