import React from "react";
import {withTranslation} from '../../../../i18n';
import ListTitle from "../../../../shared/textes/ListTitle/ListTitle";
import css from './HowTo.module.scss';
import LinksProvider from "@/services/http/LinksProvider";
import Spinner from "@/shared/Spinner/Spinner";
import Link from "next/link";

const namespacesRequired = ['home', 'common'];

const HomeHowTo = ({t, activeList}) => {
    return (
        <div className={css.notice}>
            <ListTitle title={t('home:notice.title')}
                       subtitle={t('home:notice.subtitle')}
            />


            <div className={css.timeline}>
                <div className={css.timeline__card}>
          <span>
            <h3>01</h3>
          </span>
                    <span>
            <h2>{t('concept:how.create')}</h2>
            <p>{t('concept:how.create-desc')}</p>
          </span>
                </div>

                <div className={css.timeline__card}>
          <span>
             <h3>02</h3>
          </span>
                    <span>
            <h2>{t('concept:how.share')}</h2>
            <p>{t('concept:how.share-desc')}</p>
          </span>
                </div>

                <div className={css.timeline__card}>
          <span>
            <h3>03</h3>
          </span>
                    <span>
            <h2>{t('concept:how.collect')}</h2>
            <p>{t('concept:how.collect-desc')}</p>
          </span>
                </div>

                <div className={css.timeline__card}>
          <span>
            <h3>04</h3>

          </span>
                    <span>
            <h2>{t('concept:how.spend')}</h2>
            <p>{t('concept:how.spend-desc')}</p>
          </span>
                </div>
            </div>

            <a href={!activeList
              ? LinksProvider.get(LinksProvider.ROUTES.LIST.CREATE)
              : LinksProvider.get(LinksProvider.ROUTES.LIST.LIST, {reference: activeList && activeList.reference})}
              className={css.btn__create}>
              {!activeList ? t('common:navbar.create') : t('common:consult-list')}

            </a>

        </div>
    )
};

export default withTranslation(namespacesRequired)(HomeHowTo);
