import React, {useEffect, useState, Fragment} from 'react';
import Button from '../../../../shared/button/simpleButton/Button';
import {withTranslation} from '../../../../i18n';
import Swiper from 'react-id-swiper';
import Link from 'next/link';
import LinksProvider from '../../../../services/http/LinksProvider';
import Spinner from '../../../../shared/Spinner/Spinner';
import css from './HomeHeader.module.scss';
import PromoCodesService from '@/services/domain/PromoCodesService';
import PromoCodeBanner from '@/pages/home/components/PromoCodeBanner/PromoCodeBanner';

const namespacesRequired = ['home'];

const HomeHeader = ({t, activeList, isFetching, pagesData}) => {


  const [windowWidth, setWindowWidth] = useState(null);
  const [bannerPromoCode, setBannerPromoCode] = useState(null);
  const advantages = ["voyage", "partners", "bonus"];

  const params = {
    centeredSlides: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };


  const handlePageResize = (e) => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handlePageResize);
    setWindowWidth(window.innerWidth);
  });

  useEffect(() => {
    if (pagesData && pagesData.promoCodeBanner) {
      PromoCodesService.get(pagesData.promoCodeBanner.reference)
        .then(setBannerPromoCode);
    }
  }, []);


  const Advantage = ({image, title, desc}) => {
    return (
      <div className={css.advantage}>
        <img src={image}/>
        <h2>
          {title}
          <span>{desc}</span>
        </h2>
      </div>
    );
  };

  const MobileAdvantage = () => {
    return (
      <div>
        <Swiper {...params}>
          {advantages.map((advantage, index) => {
            return (
              <div
                className={css.mobile_container}
                key={index}
              >
                <Advantage image={`/static/images/home/advantages/icon-${advantage}.svg`}
                           title={t(`home:advantages.advantage_${index}.title`)}
                           desc={t(`home:advantages.advantage_${index}.desc`, {bonus: 5})}
                />
              </div>
            );
          })}
        </Swiper>
      </div>
    );
  };


  return (
    <header className={css.header}>
      <div className={css.header_container}>
        <h1 dangerouslySetInnerHTML={{__html: t('home:title')}}
            className={css.title}
        />

        <div className={css.btn__container}>
            <a href={!activeList
              ? LinksProvider.get(LinksProvider.ROUTES.LIST.CREATE)
              : LinksProvider.get(LinksProvider.ROUTES.LIST.LIST, {reference: activeList && activeList.reference})}> <Button className={css.btn__primary}>
              {isFetching ? <Spinner/> : (!activeList
                ? t('common:navbar.create')
                : t('common:consult-list'))}
            </Button>
            </a>


          <Link href={LinksProvider.get(LinksProvider.ROUTES.LIST.SEARCH_NO_PARAMS)}>
            <a>
              <Button className={css.btn__light}>
                {t('home:participate')}
              </Button>
            </a>
          </Link>
        </div>

        {bannerPromoCode && (<PromoCodeBanner code={bannerPromoCode} customClass={css.home_container__promocode}/>)}
      </div>
      <div className={css.advantages_container}>
        <div className={css.advantages}>

          {windowWidth > 874 && (
            <Fragment>
              {advantages.map((advantage, index) => {
                return (
                  <Advantage image={`/static/images/home/advantages/icon-${advantage}.svg`}
                             title={t(`home:advantages.advantage_${index}.title`)}
                             desc={t(`home:advantages.advantage_${index}.desc`, {bonus: 5})}
                             key={index}
                  />
                );
              })}
            </Fragment>
          )}

          {windowWidth <= 874 && (
            <Fragment>
              <MobileAdvantage/>
            </Fragment>
          )}


        </div>
      </div>
    </header>
  );
};

export default withTranslation(namespacesRequired)(HomeHeader);
