import React from 'react';
import {withTranslation} from "../../../../i18n";
import Tooltip from "@/shared/Tooltip/Tooltip";
import CurrencyService from "@/services/utils/CurrencyService";
import css from './PromoCodeBanner.module.scss';

const namespacesRequired = ['common', 'pot'];

const PromoCodeBanner = ({t, code, customClass}) => {

  const tooltipHasContent = code.duration_before_reaching_threshold || code.min_collected_amount || code.min_collected_amount;

  const PromoCodeBannerTooltipContent = () => {
    return (
      <div>
        {code.duration_before_reaching_threshold && (
          <div
            className={css.threshold__disclaimer}
          >{t('pot:promocode.conditions-diclaimer', {count: code.duration_before_reaching_threshold})}</div>
        )}
        <ul>
          {code.min_participants_number !== null && (
            <li>
              - {t('pot:promocode.min-participants', {count: code.min_participants_number})}
            </li>
          )}

          {code.min_collected_amount > 0 && (
            <li>
              - {t('pot:promocode.min-amount', {amount: (code.min_collected_amount / 100)})}
            </li>
          )}
        </ul>
      </div>
    );
  };

  return (
    <div className={`${css.promocode__banner} ${customClass ? customClass : ''}`}>
      <span dangerouslySetInnerHTML={{
        __html: code.banner_text ? code.banner_text : t('pot:promocode.offer', {
          amount: CurrencyService.centsToEuro(code.amount),
          reference: code.reference
        })
      }}
      />
      {tooltipHasContent && (
        <Tooltip customImageUrl={'/static/images/pictos/icon-question-mark-white.svg'}
                 trigger="click">
          <PromoCodeBannerTooltipContent />
        </Tooltip>
      )}
    </div>)
};

export default withTranslation(namespacesRequired)(PromoCodeBanner);
